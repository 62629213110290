import React, { useState } from "react";
import Toast from "sushiweb/Toast";

const ShowToast = (props) => {
    const {
        errorMsg
    } = props;
    const [show,setShow] = useState(true)

    const handleClose=()=>{
        setShow(false)
    }

    return(
        <React.Fragment>
            <Toast
                show={show}
                align="bottom"
                onClose={handleClose}
                text={errorMsg?.length == 0 ? "Something went wrong" : errorMsg}
                type="error"
                autoHideDuration={5}
            />
        </React.Fragment>
    )
}

export default ShowToast;