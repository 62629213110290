import {
  BottomModal,
  Button,
  Card,
  Details,
  FlexColumn,
  SeparatorLine,
  SushiModal,
  VerticalLine,
  WhiteWrapper,
  Wrapper,
} from "../Common/styles.common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useVendorStore from "../HomePage/useVendorStore";
import { Loader } from "../Common/Loader";
import { CardWrapper } from "./styles.grn";
import Invoice from "./Invoice";
import GenerateCN from "./generateCN";
import { strings } from "utils/constants/strings";
import {SELLER_STATUS_APPROVED, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL, STATUS_INIT} from "utils/constants/others";
import BackButton from "../Button/BackButton";
import Cross from "sushiweb/Icons/all/Cross";
import Spinner from "sushiweb/Spinner/Spinner";
import ShowToast from "../Common/Toast";

const GrnDetails = () => {
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  let { source, grnId, purchaseOrderId } = useParams();
  const getPoGrnMappingStatus = useVendorStore(
    (state) => state.getPoGrnMappingStatus
  );
  const poGrnList = useVendorStore((state) => state.poGrnList);
  const [grn, setGrn] = useState("");
  const [generateCn, setGenerateCn] = useState(false);
  const [po, setPo] = useState("");
  const [items, setItems] = useState("");
  const [openDiscrepancyReasonModal, setOpenDiscrepancyReasonModal] =
    useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [discrepancyItem, setDiscrepancyItem] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const sellerDetails = useVendorStore((state) => state.sellerDetails);
  const updateSellerApprovalStatus = useVendorStore((state)=> state.updateSellerApprovalStatus)
  const updateSellerStatus = useVendorStore((state)=> state.updateSellerStatus)
  const getSellerDetails = useVendorStore((state) => state.getSellerDetails);
  const [openGrnConfirmedModal, setOpenGrnConfirmedModal] = useState(false);
  const updateEntityStatus = useVendorStore(
    (state) => state.updateEntityStatus
  );

  const updateSellerStatusSuccessCallback = () => {
    setOpenGrnConfirmedModal(!openGrnConfirmedModal);
    setGenerateCn(false)
  };

  const closeConfirmGrnModal = () => {
    setOpenGrnConfirmedModal(false);
    if(source==="all"){
      navigate("/po/all")
    } else {
      navigate("/grn/pending");
    }
  };

  const HandleSellerStatusUpdate = (id) => {
    poGrnList[0]?.cn_data?.total_cn_amount !== 0
      ? (() => {
          setShowToast(false);
          setGenerateCn(true);
        })()
      : updateSellerStatus(
          {
            entity_id: id,
            entity_type: "PO_GRN_MAPPING",
            seller_status: SELLER_STATUS_APPROVED,
            account_id: window.localStorage.getItem("outlet_id"),
          },
          updateSellerStatusSuccessCallback
        );
  };  

  const handleCNCreationEvent = () => {
    updateEntityStatus("createAndGetCnStatus", STATUS_INIT);
    updateSellerStatus(
      {
        entity_id: grn.id,
        entity_type: "PO_GRN_MAPPING",
        seller_status: SELLER_STATUS_APPROVED,
        account_id: window.localStorage.getItem("outlet_id"),
      },
      updateSellerStatusSuccessCallback
    );
  }

  const handleCNCreationFailureEvent = () => {
    setErrorMsg("Error while confirming grn")
    setGenerateCn(false)
    toggleConfirmationModal()
    setShowToast(true)
  }

  useEffect(() => {
    getPoGrnMappings({
        po_grn_mapping_id: grnId,
        is_po_grn_items_required: true,
        get_cn_data:true,
      },true);
    getPurchaseOrders({
        is_item_required: true,
        purchase_order_id: purchaseOrderId,
      },true);
    getSellerDetails({
      phone_number: window.localStorage.getItem("phone_number"),
    });
  }, [])

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_DONE && poGrnList) {
      setGrn(poGrnList[0]);
    }
  }, [poGrnList]);

  useEffect(() => {
    if (grn.po_grn_items) {
      setItems(grn.po_grn_items);
    }
  }, [grn]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
      setPo(purchaseOrders[0]);
    }
  }, [purchaseOrders]);

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPoGrnMappingStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [getPoGrnMappingStatus]);

  useEffect(() => {
    if (updateSellerApprovalStatus === STATUS_FAIL) {
      setShowToast(true)
    }
    if (updateSellerApprovalStatus === STATUS_DONE) {
      setShowToast(false);
    }
  }, [updateSellerApprovalStatus]);

  const toggleDiscrepancyModal = (item) => {
    setDiscrepancyItem(item);
    setOpenDiscrepancyReasonModal(!openDiscrepancyReasonModal);
  };

  const generateInvoiceCTA = () => {
    if (source === "all") {
      navigate(`/grn/invoice/all/${purchaseOrderId}/${grnId}`);
    } else {
      navigate(`/grn/invoice/pending/${purchaseOrderId}/${grnId}`);
    }
  };
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const toggleConfirmationModal = () => {
      setOpenConfirmationModal(!openConfirmationModal);
  };

  return (
    <React.Fragment>
      {generateCn && <GenerateCN 
      handleCNCreationEvent = {handleCNCreationEvent}
      handleCNCreationFailureEvent = {handleCNCreationFailureEvent}
      grnId = {grnId}
      />}
      {showLoader ? (
        <Loader />
      ) : (
        <Wrapper className="d-flex flex-column">
          <div className="d-flex flex-column y-scroll">
            <Details>
              <div className="d-flex ml-4">
                <BackButton
                  pathName={source === "all" ? "/po/all" : "/grn/pending"}
                />
                <div className="d-flex flex-column ml-1 mr-4">
                  <span className="fs-18 fw-700">
                    {grn?.grn_number}
                  </span>
          <span className="fs-12 fw-400 text-gray-cool mt-1">
                    {items.length} product{items.length > 1 ? "s" : ""}
                    {" "}
                    • {grn?.purchase_order_number}
          </span>
                </div>
              </div>
              <SeparatorLine />
              <CardWrapper
                bgColor={"#F2F4F7"}
                className="align-items-center mx-4 mt-2"
              >
                <Card smallRadius={true} className="wd-100 align-items-center">
                  <div className="d-flex wd-100 justify-content-center">
                    <div className="d-flex flex-column wd-50 align-items-center">
                      <span className="fs-15 fw-700">
                        &#8377;
                        {grn.supplier_bill_amount?.toLocaleString("en-IN")}
                      </span>
                      <span className="fs-13 fw-400 text-gray-cool">
                        {strings.billAmount}
                      </span>
                    </div>
                    {grn?.cn_data?.total_cn_amount > 0 && (
                      <div className="d-flex flex-column wd-50 align-items-center">
                        <span className="fs-15 fw-700">
                          &#8377;
                          {grn?.cn_data?.total_cn_amount.toLocaleString("en-IN")}
                        </span>
                        <span className="fs-13 fw-400 text-gray-cool">
                          {strings.cnAmount}
                        </span>
                      </div>
                    )}
                    <VerticalLine />
                    <div className="d-flex flex-column wd-50 align-items-center">
                      <span className="fs-15 fw-700">
                        &#8377;{grn.grn_amount?.toLocaleString("en-IN")}
                      </span>
                      <span className="fs-13 fw-400 text-gray-cool">
                        {strings.grnAmount}
                      </span>
                    </div>
                  </div>
                </Card>
                <span className="fs-12 fw-400 text-gray-cool m-3">
                  GRN on{" "}
                  {moment(new Date(grn.created_at))
                    .format("DD MMM, YYYY")
                    .toString()}{" "}
                  • Bill no. {grn.supplier_bill_number}
                </span>
              </CardWrapper>
            </Details>
            <span className="fs-20 fw-700 ml-4 mt-5">{strings.grnDetails}</span>
            {items ? (
              items.map((item, index) => (
                <Card className="mt-4 mx-4">
                  <div className="d-flex align-items-center ml-4">
                    <img src={item.product_image} height="48px" width="48px" />
                    <FlexColumn className="ml-4">
                      <span className="fs-16 fw-500">{item.product_name}</span>
                      <span className="fs-16 fw-400 text-gray-cool">
                        &#8377;{item.po_item_price_per_unit}
                      </span>
                    </FlexColumn>
                  </div>
                  <CardWrapper
                    bgColor={"#F2F4F7"}
                    className="align-items-center mx-4 mt-4"
                  >
                    <Card
                      smallRadius={true}
                      className="wd-100 align-items-center"
                    >
                      <div className="d-flex wd-100 justify-content-center">
                        <div className="d-flex flex-column wd-50 align-items-center">
                          <span className="fs-15 fw-700">
                            {item.eligible_inward_quantity}{" "}
                            {item.buy_measurement_unit}
                          </span>
                          <span className="fs-13 fw-400 text-gray-cool mt-2">
                            {strings.invoicedQty}
                          </span>
                        </div>
                        <VerticalLine />
                        <div className="d-flex flex-column wd-50 align-items-center">
                          <span className="fs-15 fw-700">
                            {item.grn_quantity} {item.buy_measurement_unit}
                          </span>
                          <span className="fs-13 fw-400 text-gray-cool mt-2">
                            {strings.grnQty}
                          </span>
                        </div>
                      </div>
                    </Card>
                    {item.discrepancy_qty_reasons &&
                    item.discrepancy_qty_reasons.length ? (
                      <span
                        className="fs-12 fw-500 text-red-500 m-3"
                        onClick={() => toggleDiscrepancyModal(item)}
                      >
                        {strings.discrepancyReasons}
                      </span>
                    ) : (
                      <></>
                    )}
                  </CardWrapper>
                </Card>
              ))
            ) : (
              <></>
            )}
          </div>
          {grn.seller_status != "APPROVED" &&
          grn?.is_invoice_generation_allowed_for_seller_hub ? (
            <WhiteWrapper className="px-5">
              <Button
                className="fs-13 text-white fw-600"
                onClick={generateInvoiceCTA}
              >
                Click to Generate Invoice
              </Button>
              <div
                className="mt-4 text-gray-cool"
                dangerouslySetInnerHTML={{ __html: strings.pocContactDetails }}
              ></div>
            </WhiteWrapper>
          ) : grn.seller_status != "APPROVED" ? (
            <WhiteWrapper className="px-5">
              <Button
                className="fs-13 text-white fw-600"
                onClick={toggleConfirmationModal}
              >
                {grn?.cn_data?.total_cn_amount !== 0
                  ? "Confirm GRN and Generate CN"
                  : "Confirm GRN"}
              </Button>
              <div
                className="mt-4 text-gray-cool"
                dangerouslySetInnerHTML={{ __html: strings.pocContactDetails }}
              ></div>
            </WhiteWrapper>
          ) : (
            <></>
          )}
        </Wrapper>
      )}

      <BottomModal
        visible={openDiscrepancyReasonModal}
        onClose={toggleDiscrepancyModal}
        showCloseIcon={true}
      >
        <FlexColumn className="align-items-center p-4">
          <Cross
            color={"#696969"}
            className="close"
            onClick={toggleDiscrepancyModal}
          />
          <img
            src={discrepancyItem.product_image}
            height="80px"
            width="80px"
            className="mt-2"
          />
          <span className="fs-20 fw-700 mt-4">
            {discrepancyItem.product_name}
          </span>
          <span className="fs-16 fw-400 text-gray-cool mt-2">
            &#8377;{discrepancyItem.po_item_price_per_unit}
          </span>
          <Card className="wd-100 align-items-center mt-5 mb-4 mx-4">
            <div className="d-flex wd-100 justify-content-center">
              <FlexColumn className="wd-50 align-items-center">
                <span className="fs-15 fw-700">
                  {discrepancyItem.eligible_inward_quantity}{" "}
                  {discrepancyItem.buy_measurement_unit}
                </span>
                <span className="fs-13 fw-400 text-gray-cool mt-2">
                  {strings.invoicedQty}
                </span>
              </FlexColumn>
              <VerticalLine />
              <FlexColumn className="wd-50 align-items-center">
                <span className="fs-15 fw-700">
                  {discrepancyItem.grn_quantity}{" "}
                  {discrepancyItem.buy_measurement_unit}
                </span>
                <span className="fs-13 fw-400 text-gray-cool mt-2">
                  GRN Qty.
                </span>
              </FlexColumn>
            </div>
          </Card>
          <Card className="wd-100 mb-3 p-4">
            <span className="fs-16 fw-700">Discrepancy reasons</span>
            <SeparatorLine />
            {discrepancyItem.discrepancy_qty_reasons ? (
              discrepancyItem.discrepancy_qty_reasons.map((reason, index) => (
                <div className="d-flex justify-content-between mb-4">
                  <span className="fs-14 fw-400">{reason.reason}</span>
                  <span className="fs-14 fw-500">
                    {reason.quantity} {discrepancyItem.buy_measurement_unit}
                  </span>
                </div>
              ))
            ) : (
              <></>
            )}
          </Card>
        </FlexColumn>
      </BottomModal>
      <SushiModal visible={openConfirmationModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/info_logo.svg" height="80px" width="80px" />
          <span className="fs-21 fw-700 my-4 text-align-center">
          {grn?.cn_data?.total_cn_amount !== 0 ? (
                strings.confirmationGrnAndCNModal
              ) : (
                strings.confirmationGrnModal
              )}
          </span>      
          {grn?.cn_data?.total_cn_amount === 0 ? (
              <CardWrapper showBorder={true} bgColor={"#F8F9FC"} className="d-flex flex-column align-items-center wd-100 mb-4 p-4">
              <span className="fs-18 fw-600">&#8377; {grn.grn_amount?.toLocaleString("en-IN")}</span>
              <span className="fs-14 fw-400 text-gray-cool mt-2">will be paid to you</span>
            </CardWrapper>
            ) : (
              <CardWrapper showBorder={true} bgColor={"#F8F9FC"} className="d-flex flex-column align-items-center wd-100 mb-4 p-4">
              <>
                <span className="fs-18 fw-600 green-color">&#8377; {(grn.grn_amount - grn?.cn_data?.total_cn_amount).toLocaleString("en-IN")}</span>
                <span className="fs-14 fw-400 text-gray-cool mt-2 my-3">will be paid to you</span>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2">GRN Amount</span>
                  <span className="fs-14 fw-600 ml-6">+ &#8377; {grn.grn_amount?.toLocaleString("en-IN")}</span>
                </div>
                <div className="cn-table-class">
                  <td className="fs-14 fw-400 text-gray-cool mt-2 ">CN Amount</td>
                  <span className="fs-14 fw-600 ml-6">- &#8377; {grn?.cn_data?.total_cn_amount.toLocaleString("en-IN")}</span>
                </div>
                <div className="dotted-line"></div> 
                <div className="cn-table-class">
                  <span className="fs-14 fw-400 text-gray-cool mt-2 ">Total Amount</span>
                  <span className="fs-14 fw-600 ml-6">&#8377; {(grn.grn_amount - grn?.cn_data?.total_cn_amount).toLocaleString("en-IN")}</span>
                </div>
              </>
              </CardWrapper>
            )}          
          <Button
            className="fs-13 text-white fw-600 mb-3"
            onClick={() => HandleSellerStatusUpdate(grn.id, po.id)}
            disabled={showButtonLoader}
          >
            {showButtonLoader? 
            <Spinner color={"white"} />
            :
            "Yes, confirm"}
          </Button>
          <span className="fs-14 fw-500 text-red-500" onClick={toggleConfirmationModal}>No, go back</span>
        </FlexColumn>
      </SushiModal>
      <SushiModal visible={openGrnConfirmedModal} isCentered>
        <FlexColumn className="align-items-center p-5">
          <img src="/logos/tick_logo.svg" height="80px" width="80px" />
          <span className="fs-22 fw-700 my-4 text-align-center">
          {grn?.cn_data?.total_cn_amount !== 0 ? (
                "GRN and CN Confirmed "
              ) : (
                "GRN Confirmed "
              )}
            </span>
          <span
            className="fs-14 fw-500 text-red-500"
            onClick={closeConfirmGrnModal}
          >
            {" "}
            Okay
          </span>
        </FlexColumn>
      </SushiModal>
      {showToast?
        <ShowToast
        errorMsg={errorMsg}
        />
        
        :
        <></>
        }
    </React.Fragment>
  );
};

export default GrnDetails;
