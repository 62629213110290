import React, {useEffect, useState} from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useParams } from "react-router-dom";
import {
    Button, FlexColumn, SushiModal,
    WhiteWrapper,
    Wrapper,
} from "../Common/styles.common";
import { Loader } from "../Common/Loader";
import {PO_ORDER_TAG_MANDI_DD, PROFILE_INPUT_BORDER, STATUS_BEGIN, STATUS_DONE} from "utils/constants/others";
import styles from "../Login/Login.module.scss";
import Input from "sushiweb/InputTypes/Input";
import UploadCard from "./UploadCard";
import ShowToast from "../Common/Toast";
import DDPoHeader from "../Header/DDPoHeader";
import PreviewInvoice from "./PreviewInvoice";
import PreviewReuploadedInvoice from "./PreviewReuploadedInvoice";
import { PDFDocument } from 'pdf-lib';
import moment from "moment";
import { DataURItoBlob } from "../../../helpers/helper"

const DDPoInvoiceForm = ({pageSource,poNumber,presignedUrl,documentPath}) => {
    const pageSourceValue = pageSource || "form";
    const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
    const getPurchaseOrderStatus = useVendorStore(
        (state) => state.getPurchaseOrderStatus
    );
    const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
    const [po, setPo] = useState("");
    const [items, setItems] = useState("");
    let { source,purchaseOrderId } = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [tonnage,setTonnage] = useState(0)
    const [showError, setShowError] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [errorMsg, setErrorMsg] = useState({});
    const [showToast,setShowToast] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [showFile, setShowFile] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [imageArray, setImageArray] = useState([]);
    const [pngArray, setPngArray] = useState([]);
    const [formData, setFormData] =useState({});
    const todayDate = moment(new Date()).local(true).format('YYYY-MM-DD');
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (pageSource === "reuploadInvoice") {
            getPurchaseOrders({
                is_item_required: true,
                purchase_order_number: poNumber,
                is_delivery_details_required: true,
            },true);
        }else{
        getPurchaseOrders({
            is_item_required: true,
            purchase_order_id: purchaseOrderId,
        },true);
    }
    }, []);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
            setPo(purchaseOrders[0]);
        }
    }, [purchaseOrders]);

    useEffect(() => {
        if (po && po.purchase_order_items) {
            setItems(po.purchase_order_items);
        }
    }, [po]);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_BEGIN) {
            setShowLoader(true);
        }
        if (getPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
        }
    }, [getPurchaseOrderStatus]);

    useEffect(()=>{
        calculateTonnage();
    },[items])

    const calculateTonnage =()=>{
        let total = 0;
        for(const item of items){
            total=total+ item.quantity_ordered* item.weight_per_packet
        }
        setTonnage(total)
    }

    const closePopupHandler = () => {
        setShowPopup(false);
    };

    const setUpdateInvoiceDetails = (val, field) => {
        if (field === "InvoiceNumber") {
            setInvoiceDetails({
                ...invoiceDetails,
                invoice_number: val
            })
        } else if (field === "InvoiceDate") {
            setInvoiceDetails({
                ...invoiceDetails,
                invoice_date: val
            })
        } else if (field === "InvoiceAmount") {
            if (val == '' || /^(?!0\d)(\d+(?:\.\d{0,2})?)$/.test(val)) {
                setInvoiceDetails({
                    ...invoiceDetails,
                    invoice_amount: val
                })
            }
        } else if (field === "InvoiceUrl") {
            setInvoiceDetails({
                ...invoiceDetails,
                supplier_bill_image: val
            })
        }
        if (imageArray?.length > 0 && ((invoiceDetails.invoice_amount !== 0) &&
            invoiceDetails.invoice_number && invoiceDetails.invoice_number?.length !== 0 &&
            invoiceDetails.supplier_bill_image && invoiceDetails.supplier_bill_image?.length !== 0)) {
            navigateToInvoicePdf(invoiceDetails)
        }
        setShowError(false);
        setErrorMsg("");
    }

    const wrapperToNavigateToInvoicePdf = async (invoiceDetails) => {
        if (checkIfMissingFields(invoiceDetails)) {
            // if (!invoiceDetails.invoice_amount || parseFloat(invoiceDetails.invoice_amount) == 0) {
            //     setShowError(true);
            //     setErrorMsg({
            //         ...errorMsg,
            //         invoice_amount_error: "Invoice amount can not be 0"
            //     });
            // }
            // if (!invoiceDetails.invoice_number || invoiceDetails.invoice_number?.length == 0) {
            //     setShowError(true);
            //     setErrorMsg({
            //         ...errorMsg,
            //         invoice_number_error: "Invoice number can not be empty"
            //     });
            // }
            // if (!invoiceDetails.invoice_date) {
            //     setShowError(true);
            //     setErrorMsg({
            //         ...errorMsg,
            //         invoice_date_error: "Invoice date can not be empty"
            //     });
            // }
            if (imageArray?.length === 0 && !pdfBlob) {
                setShowError(true);
                setErrorMsg({
                    ...errorMsg,
                    invoice_image_error: "Supplier bill image is missing"
                });
                setShowToast(false);
                setShowToast(true);
            }
            // if (invoiceDetails.invoice_date && invoiceDetails.invoice_date > todayDate) {
            //     setShowError(true);
            //     setErrorMsg({
            //         ...errorMsg,
            //         invoice_date_error: "Invoice date can not be more than today's date"
            //     });
            // }
        } else {
            if (imageArray.length > 0) {
                generatePDF()
                    .then((pdf) => {
                        const formData = new FormData();
                        formData.append('file', DataURItoBlob(pdf), 'Supplier-bill-image.pdf');
                        formData.append("fileType", "supplier_bill_image");
                        setFormData(formData);
                        displayInvoicePdf(DataURItoBlob(pdf));
                        setShowPDF(true);
                    })
            } else {
                setShowPDF(true);
            }
        }
    }

    const checkIfMissingFields = (invoiceDetails) => {
        // if ((!invoiceDetails.invoice_amount || parseFloat(invoiceDetails.invoice_amount) === 0) ||
        //     (!invoiceDetails.invoice_number || invoiceDetails.invoice_number?.length == 0) ||
        //     !invoiceDetails.invoice_date || (imageArray?.length === 0 && !pdfBlob)) {
        //     return true
        // }
        // if (invoiceDetails.invoice_date && invoiceDetails.invoice_date > todayDate) {
        //     return true;
        // }
        if (imageArray?.length === 0 && !pdfBlob) {
            return true;
        }
        return false
    }

    const generatePDF = async () => {
        const pdfDoc = await PDFDocument.create();

        for (const imageData of pngArray) {
            const image = await pdfDoc.embedPng(imageData);
            const page = pdfDoc.addPage([image.width, image.height]);
            const { width, height } = page.getSize();
            page.drawImage(image, {
                x: 0,
                y: 0,
                width,
                height,
            });
        }

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        return pdfDataUri
    };

    const navigateToInvoicePdf = (invoiceDetails) => {
        if (invoiceDetails.invoice_amount == 0) {
            setShowError(true);
            setErrorMsg({
                invoice_amount_error: "Invoice amount can not be 0"
            });
        } else if (!invoiceDetails.invoice_number || invoiceDetails.invoice_number?.length == 0) {
            setShowError(true);
            setErrorMsg({
                invoice_number_error: "Invoice number can not be empty"
            });
        } else if (!invoiceDetails.invoice_date) {
            setShowError(true);
            setErrorMsg({
                invoice_date_error: "Invoice date can not be empty"
            });
        } else if (!invoiceDetails.supplier_bill_image || invoiceDetails.supplier_bill_image?.length == 0) {
            setShowError(true);
            setErrorMsg({
                invoice_image_error: "Supplier bill image is missing"
            });
            setShowToast(false);
            setShowToast(true);
        } else {
            setShowError(false);
            setErrorMsg("");
            setShowToast(false);
            displayInvoicePdf();
            setShowPDF(true);
        }
    }

    const displayInvoicePdf=(blob) => {
        if (blob) {
            setPdfBlob(blob);
        } else {
            fetch(invoiceDetails.supplier_bill_image)
                .then(resp => resp.arrayBuffer())
                .then(buffer => {
                    const blob = new Blob([buffer], {type: 'application/pdf'});
                    setPdfBlob(blob);
                })
        }
    }

    return (
        <React.Fragment>
            {showLoader ? (
                <Loader />
            ) : (showPDF && po)? (
                pageSource === "reuploadInvoice" ?
                <PreviewReuploadedInvoice
                    poId={po.id}
                    purchaseOrderNumber={po.purchase_order_number}
                    itemsLength={items.length}
                    tonnage={tonnage}
                    pdfBlob={pdfBlob}
                    invoiceDetails={invoiceDetails}
                    setShowFile={setShowFile}
                    formData={formData}
                    params={invoiceDetails}
                    po={po}
                    presignedUrl={presignedUrl}
                    documentPath = {documentPath}
                /> :
                <PreviewInvoice
                    poId={po.id}
                    purchaseOrderNumber={po.purchase_order_number}
                    itemsLength={items.length}
                    tonnage={tonnage}
                    pdfBlob={pdfBlob}
                    invoiceDetails={invoiceDetails}
                    setShowFile={setShowFile}
                    formData={formData}
                    params={invoiceDetails}
                    po={po}
                ></PreviewInvoice>
            ) : po ? (
                <Wrapper>
                    <DDPoHeader
                        poId={po.id}
                        purchaseOrderNumber={po.purchase_order_number}
                        itemsLength={items.length}
                        tonnage={tonnage}
                        source={pageSourceValue}
                    ></DDPoHeader>
                    <div className="d-flex flex-column po-content">
                        {(po.order_tag === "DIRECT_DELIVERY" && po.is_multiple_grn_enabled === true && po.ref_order_number && po.ref_order_number.length > 0) || (po.order_tag === PO_ORDER_TAG_MANDI_DD && po.is_multiple_grn_enabled === true) && po.ref_order_number != "MERCHANT_BULK" ?
                        <WhiteWrapper className="invoice-form ml-2 mr-2 mt-1">
                           <h4>Invoice details</h4>
                           <Input
                                type="string"
                                label="Invoice Number"
                                borderColor={PROFILE_INPUT_BORDER}
                                value={invoiceDetails.invoice_number}
                                errorMsg={showError ? errorMsg.invoice_number_error : ""}
                                className={styles.InputPhoneNumberStyle}
                                onChange={(value) =>
                                    setUpdateInvoiceDetails(value, "InvoiceNumber")
                                }
                            />
                            {/* <Input
                                type="date"
                                label="Invoice date"
                                borderColor={PROFILE_INPUT_BORDER}
                                value={invoiceDetails.invoice_date}
                                errorMsg={showError ? errorMsg.invoice_date_error : ""}
                                className={styles.InputPhoneNumberStyle}
                                onChange={(value) =>
                                    setUpdateInvoiceDetails(value, "InvoiceDate")
                                }
                                max={moment(new Date()).toISOString().split('T')[0]}
                            /> */}
                            <Input
                                type="number"
                                label="Invoice amount including taxes"
                                borderColor={PROFILE_INPUT_BORDER}
                                value={invoiceDetails.invoice_amount}
                                errorMsg={showError ? errorMsg.invoice_amount_error : ""}
                                className={styles.InputPhoneNumberStyle}
                                onChange={(value) =>
                                    setUpdateInvoiceDetails(value, "InvoiceAmount")
                                }
                            />
                        </WhiteWrapper>
                        : <></>}
                        <WhiteWrapper className="mt-2 invoice-form ml-2 mr-2">
                            <h4>Upload invoice</h4>
                            <span className="fs-9 fw-400 text-gray-cool">(supported file types for images are .jpeg, .jpg, .png and .tiff)</span>
                            <span className="fs-9 fw-400 text-gray-cool mt-1 mb-2">(supported file types for document is .pdf)</span>
                            <UploadCard
                                showFile={showFile}
                                setShowFile={setShowFile}
                                setUpdateInvoiceDetails={setUpdateInvoiceDetails}
                                showImages={showImages}
                                setShowImages={setShowImages}
                                imageArray={imageArray}
                                setImageArray={setImageArray}
                                pngArray={pngArray}
                                setPngArray={setPngArray}
                                setPdfBlob={setPdfBlob}
                                setFormData={setFormData}
                                setShowPopup={setShowPopup}
                            ></UploadCard>
                        </WhiteWrapper>
                    </div>
                    <WhiteWrapper className="po-bottom">
                        <Button
                            className="fs-13 text-white fw-600"
                            onClick={() => wrapperToNavigateToInvoicePdf(invoiceDetails)}
                        >
                            Continue
                        </Button>
                    </WhiteWrapper>
                    <SushiModal visible={showPopup} isCentered>
                        <FlexColumn className="align-items-center p-5">
                            <span className="fs-22 fw-700 my-4">Please upload only supported file types</span>
                            <span
                                className="fs-14 fw-500 text-red-500"
                                onClick={closePopupHandler}
                            >{" "}
                                Okay
                                </span>
                        </FlexColumn>
                    </SushiModal>
                </Wrapper>
            ) : <></>}
            {showToast?
                <ShowToast
                    errorMsg={errorMsg.invoice_image_error}
                />
                :
                <></>
            }
        </React.Fragment>
    );
};

export default DDPoInvoiceForm;
