import React, {useState, useEffect} from "react";
import {
  Button,
  Card,
  SeparatorLine,
  VerticalLine,
} from "../Common/styles.common";
import { CardWrapper, InvoiceButton, PaidTag, UnpaidTag, ViewCreditNoteButton, InvoiceButtonWithDownCNButton } from "./styles.grn";
import moment from "moment";
import { strings } from "utils/constants/strings";
import { DownloadPDF } from "operations/operations";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export const ConfirmedGrnCard = (props) => {
  const [showCnDownloadButton, setShowCnDownloadButton] = useState(false);
  const grn = props.grn;
  const navigate = useNavigate();


useEffect(() => {
  if (grn.cn_data?.cn_items?.length!==0) {
    setShowCnDownloadButton(true);
  }
}, [grn.cn_data?.cn_items]);

  const downloadInvoice =() => {
    if(grn.supplier_bill_image){
        DownloadPDF(grn.supplier_bill_image, `Invoice-${grn.grn_number}`, (error) => {
        });
        if (window && window.downloadFileMessageHandler) {
          window.downloadFileMessageHandler.postMessage(grn.supplier_bill_image);
        }
    }
  }
  
  const ShowCreditNotes = () => {
      navigate(`/grn/creditNote/${grn.id}`);
  };

  return (
    <React.Fragment>
      <Card className="mt-4 mx-4">
        <div className="d-flex flex-column ml-4 mr-4">
          <span className="fs-18 fw-700">{grn?.grn_number}</span>
          <span className="fs-12 fw-400 text-gray-cool mt-1">
            {grn?.purchase_order_number}
          </span>
        </div>
        <CardWrapper bgColor={"#F2F4F7"} className="align-items-center mx-4 mt-4">
          <Card smallRadius={true} className="wd-100 align-items-center">
            <div className="d-flex wd-100 justify-content-between px-4 align-items-center">
              <div className="d-flex flex-column align-items-center">
                <span className="fs-15 fw-700">&#8377;{grn.grn_amount}</span>
                <span className="fs-13 fw-400 text-gray-cool mt-1">
                  GRN amount
                </span>
              </div>

              {grn.purchase_order_payment_id != 0 ? (
                <PaidTag>
                  <span className="fs-11 fw-500">
                    {strings.paymentDone}{" "}
                    {moment(
                      new Date(grn.purchase_order_payment_details.payment_date)
                    )
                      .format("DD MMM, YYYY")
                      .toString()}
                  </span>
                </PaidTag>
              ) : (
                <UnpaidTag>
                  <span className="fs-11 fw-500">
                    {strings.paymentProcessing}
                  </span>
                </UnpaidTag>
              )}
            </div>
          </Card>
          {grn.po_grn_seller_status_history.length ? (
            <span className="fs-12 fw-400 text-gray-cool m-3">
              GRN confirmed on{" "}
              {moment(
                new Date(grn.po_grn_seller_status_history[0].history_created_at)
              )
                .format("DD MMM, YYYY")
                .toString()}
            </span>
          ) : (
            <></>
          )}
        </CardWrapper>
        <div className="mx-4 mt-4 d-flex align-items-center">
  {showCnDownloadButton ? (
    <InvoiceButtonWithDownCNButton onClick={downloadInvoice}>
      <img src="/logos/invoice_logo.svg" height="16px" width="16px" />
      <span className="fs-13 fw-600 text-red-500 ml-2">Download invoice</span>
    </InvoiceButtonWithDownCNButton>
  ) : (
    <InvoiceButton onClick={downloadInvoice}>
      <img src="/logos/invoice_logo.svg" height="16px" width="16px" />
      <span className="fs-13 fw-600 text-red-500 ml-2">Download invoice</span>
    </InvoiceButton>
  )}
  {showCnDownloadButton && (
    <ViewCreditNoteButton onClick={ShowCreditNotes} className="ml-4">
      <span className="fs-13 fw-600 text-red-500 ml-2">View Credit Notes</span>
    </ViewCreditNoteButton>
  )}
</div>

      </Card>
    </React.Fragment>
  );
};
