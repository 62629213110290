import moment from "moment/moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useVendorStore from "../HomePage/useVendorStore";
import { DownloadPDF } from "operations/operations";
import { CardWrapper, DownloadCreditNoteButton } from "./styles.grn";
import ShowToast from "../Common/Toast";

import {
  FlexColumn,
  Wrapper,
  Card,
  SeparatorLine,
  VerticalLine,
  Details,
} from "../Common/styles.common";
import BackButton from "../Button/BackButton";
import { strings } from "utils/constants/strings";

const CreditNote = () => {
  const { poGrnList } = useVendorStore();
  const [items, setItems] = useState([]);
  let { grnId } = useParams();
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const [grn, setGrn] = useState();
  const [showToast, setShowToast] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const foundGrn = poGrnList?.find((item) => item.id == grnId);
    setGrn(foundGrn);
  }, [poGrnList, grnId]);

  useEffect(() => {
    grn ? setItems(grn.cn_data.cn_items) : setItems([]);
  }, [grn]);

  useEffect(() => {
    getPoGrnMappings(
      {
        po_grn_mapping_id: grnId,
        is_po_grn_items_required: true,
        get_cn_data: true,
      },
      true
    );
  }, []);

  const downloadInvoice = (cn) => {
    if (cn?.cn_path.length > 0) {
      DownloadPDF(cn.cn_path, `Invoice-${cn.cn_number}`, (error) => {
        setErrorMsg("Error while downloading credit note");
        setShowToast(true);
      });
      if (window && window.downloadFileMessageHandler) {
        window.downloadFileMessageHandler.postMessage(cn.cn_path);
      }
    }
  };
  return (
    <React.Fragment>
      <Wrapper className="d-flex flex-column">
        <div className="d-flex flex-column y-scroll">
          <Details>
            <div className="d-flex ml-4">
              <BackButton pathName={"/po/all"} />
              <div className="d-flex flex-column ml-1 mr-4">
                <span className="fs-18 fw-700">
                  {grn?.purchase_order_number}
                </span>
              </div>
            </div>
            <SeparatorLine />
            <CardWrapper
              bgColor={"#F2F4F7"}
              className="align-items-center mx-4 mt-2"
            >
              <Card smallRadius={true} className="wd-100 align-items-center">
                <div className="d-flex wd-100 justify-content-center">
                  <div className="d-flex flex-column wd-50 align-items-center">
                    <span className="fs-15 fw-700">
                      &#8377;
                      {grn?.supplier_bill_amount?.toLocaleString("en-IN")}
                    </span>
                    <span className="fs-13 fw-400 text-gray-cool">
                      {strings.billAmount}
                    </span>
                  </div>
                  <div className="d-flex flex-column wd-50 align-items-center">
                    <span className="fs-15 fw-700">
                      &#8377;
                      {grn?.cn_data?.total_cn_amount?.toLocaleString("en-IN")}
                    </span>
                    <span className="fs-13 fw-400 text-gray-cool">
                      {strings.cnAmount}
                    </span>
                  </div>
                  <VerticalLine />
                  <div className="d-flex flex-column wd-50 align-items-center">
                    <span className="fs-15 fw-700">
                      &#8377;{grn?.grn_amount?.toLocaleString("en-IN")}
                    </span>
                    <span className="fs-13 fw-400 text-gray-cool">
                      {strings.grnAmount}
                    </span>
                  </div>
                </div>
              </Card>
              <span className="fs-12 fw-400 text-gray-cool m-3">
                GRN on{" "}
                {moment(new Date(grn?.created_at))
                  .format("DD MMM, YYYY")
                  .toString()}{" "}
                • Bill no. {grn?.supplier_bill_number}
              </span>
            </CardWrapper>
          </Details>
          <span className="fs-20 fw-700 ml-4 mt-20">{strings.creditNotes}</span>
          <span className="fs-14 fw-400 ml-4 mt-1 text-gray-cool">{`based on cashback of ${grn?.cn_data?.discount_percentage}% of GRN amount`}</span>
          <CardWrapper
            bgColor={"#F2F4F7"}
            className="align-items-center mx-4 mt-2"
          ></CardWrapper>
          {items?.length > 0 ? (
            items.map((item, index) => (
              <CardWrapper
                bgColor={"#F2F4F7"}
                className="align-items-center mx-4 mt-4"
              >
                <Card
                  smallRadius={true}
                  className="wd-100 align-items-center px5"
                >
                  <div className="d-flex wd-100 justify-content-center">
                    <div className="d-flex align-items-center cn-table-class">
                      <div className="d-flex align-items-center">
                        <FlexColumn className="">
                          <span className="fs-16 fw-600 mb-2">
                            {item?.cn_number}
                          </span>
                          <span className="fs-12 fw-400 text-gray-cool mb-1">
                            {"Amount  : "}
                            &#8377;
                            {item?.cn_amount}
                          </span>
                          <span className="fs-12 fw-400 text-gray-cool mb-1">
                            {" "}
                            {"Created on : "}
                            {moment(new Date(item?.cn_created_on))
                              .format("DD MMM, YYYY")
                              .toString()}
                          </span>
                        </FlexColumn>
                      </div>
                      {
                        <DownloadCreditNoteButton
                          onClick={() => {
                            downloadInvoice(item);
                          }}
                          className="ml-4"
                        >
                          <span className="fs-13 fw-600 text-red-500 ml-2">
                            Download
                          </span>
                        </DownloadCreditNoteButton>
                      }
                    </div>
                  </div>
                </Card>
              </CardWrapper>
            ))
          ) : (
            <></>
          )}
        </div>
      </Wrapper>
      {showToast ? <ShowToast errorMsg={errorMsg} /> : <></>}
    </React.Fragment>
  );
};
export default CreditNote;
